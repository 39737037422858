<template>
  <div style="touch-action: manipulation;">
    <v-container fluid>
      <v-layout v-if="$vuetify.breakpoint.mdAndUp">
        <v-flex md6 lg7>
          <Grid :pos-type="posType" responsive="0"></Grid>
        </v-flex>

        <v-flex md3>
          <Invoice :pos-type="posType"/>
        </v-flex>

        <v-flex md3 lg2>
          <Buttons :pos-type="posType"/>
        </v-flex>
      </v-layout>

      <v-layout v-if="$vuetify.breakpoint.smAndDown">
        <v-flex sm12>
          <Grid :pos-type="posType" responsive="1"></Grid>
        </v-flex>
      </v-layout>
    </v-container>

    <Footer :pos-type="posType"/>

    <barcode-keyboard :pos-type="posType" include-item-booking></barcode-keyboard>

    <!-- SHIFT -->
    <StartShiftCounting />


    <BookItem ref="bookItem" :pos-type="posType"></BookItem>
  </div>
</template>

<script>
import Invoice from "../../components/pos/Invoice";
import Buttons from "../../components/pos/Buttons";
import Footer from "../../components/pos/Footer";
import Grid from "../../components/pos/Grid";
import BarcodeKeyboard from "../../mixins/barcodeKeyboard";

import {ENDPOINTS} from "@/config";
import {mapGetters, mapState} from "vuex";
import BookItem from "@/mixins/pos/BookItem";
import StartShiftCounting from "@/components/pos/StartShiftCounting";
import {Events} from "@/plugins/events";

export default {
  name: "WholesaleRetail",

  components: {
    StartShiftCounting,
    BookItem,
    BarcodeKeyboard,
    Grid,
    Footer,
    Buttons,
    Invoice,
  },

  sockets: {
    "customerdisplay.setTip": function (payload){
      this.$store.commit("pos/setCustomerDisplayTipPercent",payload)
    }
  },

  data() {
    return {
      posType: "wholesaleRetail",
    }
  },

  computed: {
    ...mapState([
      'api',
      'pos',
      'settings',
      'posLayoutTemplates',
      'tse'
    ]),
    ...mapGetters({
      fiscalClient: 'tse/fiscalClient'
    }),
  },

  mounted() {
    this.$store.commit("pos/setCustomerDisplayTipPercent", null);
    //CHECK IF TSE CLIENT IS AVAILABLE
    if (this.fiscalClient !== null) {

      if (this.fiscalClient.device.type === null) {
        this.fiscalClient.device.type = "epsonTSE";
      }

      //IF TYPE IS FISKALY
      if (this.fiscalClient.device.type === "epsonTSE") {

        //CHECK IF TSE DEVICE ALREADY ADDED
        let tsePrinters = this.$eposClass.getTSEPrinters();

        if (!tsePrinters.hasOwnProperty(this.fiscalClient.id)) {
          //ADD NEW TSE PRINTER
          this.$eposClass.addTSEPrinter(new this.$epson.ePOSDevice(), {
            id: this.fiscalClient.id,
            ip: this.fiscalClient.device.ip,
            TSEProxyIPAdress: this.fiscalClient.device.TSEProxyIPAdress,
            port: this.fiscalClient.device.port,
            deviceID: this.fiscalClient.device.deviceID,
            adminID: 'Administrator',
            clientID: this.fiscalClient.clientID
          }, false, false, (this.fiscalClient.device.useTSEProxy === 1));
        } else {
          this.tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

          if (!this.tseDevice?.tseReady) {
            if (!this.tseDevice.connected) {
              //TRY TO RE-CONNECT!!!
              this.tseDevice.connect();
            }
          }
        }
      }
    }

    //CHECK IF WE HAVE TO VOID AN INVOICE
    if (this.$route.query.hasOwnProperty("voidInvoice")) {
      //GET INVOICE
      this.$refs.bookItem.selectItemEAN("R$" + this.$route.query.voidInvoice);
    }
  },

  methods: {

  }
}
</script>

<style scoped>
.container {
  padding: 0 !important;
}
</style>